#UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and !ltIE6
  IE8 = IE and ltIE8 and !ltIE7 and !ltIE6
  IE9 = IE and ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  IE10 = IE and !ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  Webkit = !document.uniqueID and !window.opera and !window.sidebar and !window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

#URL
url = do ->
  href = location.href.split '/'

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val == '' or i == href.length - 1 and val.indexOf '.'
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

    for val , i in href
      if val is 'pc' and href[i-1] is 'work'
        length = 4

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j == 0
      path += '//'

    else
      path += '/'

  return path

#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
)

window.onpageshow = (evt) ->
  if evt.persisted
    location.reload()
  return

event =
  all: 'mousedown touchstart pointerdown mouseenter mouseleave'
  type: ''
  start: ''
  enter: ''
  leave: ''
  move: ''
  end: ''
# イベント判定

event.check = (e) ->
  _default = e.type
  event.start = _default
  event.move = if _default == 'pointerdown' then 'pointermove' else if _default == 'touchstart' then 'touchmove' else 'mousemove'
  event.end = if _default == 'pointerdown' then 'pointerup' else if _default == 'touchstart' then 'touchend' else 'click'
  event.enter = if _default == 'pointerenter' then _default else if _default == 'touchenter' then _default else 'mouseenter'
  event.leave = if _default == 'pointerleave' then _default else if _default == 'touchleave' then _default else 'mouseleave'
  return

event.on = (type, elm, fn, delegate) ->
  $(document).off(event.all, elm).on event.all, elm, (e) ->
    e.preventDefault()
    event.check e
    if fn != null
      if e.type == event.enter or e.type == event.leave
        # mouseenter mouseleaveのとき、コールバック関数を即実行
        fn this, e
      else
        if type == 'bind'
          # jQuery $(element).on(event, func);
          $(elm).off(event.end).on event.end, (e) ->
            fn this, e
            return
        else if type == 'live'
          # jQuery $(document).on(event, element, func);
          $(document).off(event.end, elm).on event.end, elm, (e) ->
            fn this, e
            return
        else if type == 'delegate'
          # jQuery $(parentsElement).on(event, element, func);
          $(delegate).off(event.end, elm).on event.end, elm, (e) ->
            fn this, e
            return
    else
      # 引数にコールバック関数が定義されていないときの処理
    return
  return

$ ->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  $(".timer").yycountdown({
    endDateTime   : '2016/03/31 23:59:59'
    unit          : {d: '日', h: ':', m: ':', s: ''},
  });
  $('.main-image').bxSlider
    mode: 'fade'
    auto: true
    speed: 3000
    pause: 7000
    pager: false
    controls: false
  $('.main-text').bxSlider
    mode: 'fade'
    auto: true
    speed: 1000
    pause: 7000
    pager: false
    controls: false
  $('.case-list').bxSlider
    auto: true
    speed: 2500
    pause: 7000
    pager: false
    controls: true
  $('.about-oneworkout-pic').bxSlider
    mode: 'fade'
    auto: true
    speed: 2000
    pause: 5000
    pager: false
    controls: false
  $('.clinic-image').bxSlider
    mode: 'fade'
    auto: true
    speed: 3000
    pause: 5000
    pager: false
    controls: false
  $(".nav-item a[href^=#]").click ->
    speed = 400 # ミリ秒
    href = $(this).attr("href")
    target = $((if href is "#" or href is "" then "html" else href))
    position = target.offset().top
    $("body,html").animate
      scrollTop: position
    , speed, "swing"
    false

  nav = $(".header")
  if nav.length > 0
    navTop = nav.offset().top + 200
    navHeight = nav.height() + 10
    nav.css "top", -navHeight + "px"

  showFlag = false
  #ナビゲーションの位置まできたら表示
  $(window).scroll ->
    winTop = $(this).scrollTop()
    if winTop >= navTop
      if showFlag is false
        showFlag = true
        nav.addClass("fixed").stop().animate
          top: "0px"
        , 500
    else if winTop <= navTop
      if showFlag
        showFlag = false
        nav.stop().animate
          top: -navHeight + "px"
        , 200, ->
          nav.removeClass "fixed"
          return

    return

  return
